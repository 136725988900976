export const LOADING_OPERATION = 'LOADING_OPERATION';
export const FETCHING_OPERATION = 'FETCHING_OPERATION';

export const GET_ALL_STARTUP_REPORT = 'GET_ALL_STARTUP_REPORT';
export const GET_ALL_FINAL_REPORT = 'GET_ALL_FINAL_REPORT';
export const GET_TODAY_TERMINAL_STARTUP = 'GET_TODAY_TERMINAL_STARTUP';
export const GET_YESTERDAY_TERMINAL_FINAL = 'GET_YESTERDAY_TERMINAL_FINAL';
export const GET_VEHICLE_NOT_AT_TERMINAL = 'GET_VEHICLE_NOT_AT_TERMINAL';
export const GET_VEHICLE_NOT_ACCOUNTED_FOR = 'GET_VEHICLE_NOT_ACCOUNTED_FOR';
export const GET_VEHICLE_NOT_IN_END_OF_DAY = 'GET_VEHICLE_NOT_IN_END_OF_DAY';
export const GET_REPORT_TIME = 'GET_REPORT_TIME';

export const LISTING_RESET = 'LISTING_RESET';
export const SET_ERROR = 'SET_ERROR';
export const LOGOUT = 'LOGOUT';
