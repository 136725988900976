import { handleConvertByte } from '../../utils/convertByteArray';
import { httpRequest } from '../../https/http';
import { store } from '../store';
import * as SettingsTypes from './settingsTypes';

export const getBookingAndCustomerAnalysisByTerminalAction =
  (enqueueSnackbar, id = '', startDate = '', endDate = '') =>
  async (dispatch) => {
    dispatch({ type: SettingsTypes.LOADING_SETTINGS });

    try {
      const result = await httpRequest({
        url: `Dashboard/BookingAndCustomerAnalysis${
          id || startDate ? `?terminalId=${id}&StartDate=${startDate}&EndDate=${endDate}` : ''
        }`,
        urlType: 'settings',
      });

      if (result.code === 1) {
        dispatch({ type: SettingsTypes.BOOKING_CUSTOMER_ANALYSIS, payload: result?.data });
      }

      if (result.code !== 1) {
        dispatch({ type: SettingsTypes.LISTING_RESET });
        if (result.shortDescription) enqueueSnackbar(result.shortDescription, { variant: 'error' });
      }
    } catch (error) {
      dispatch({ type: SettingsTypes.LISTING_RESET });
      enqueueSnackbar(error?.message || 'Unable to fetch data! Please refresh and try again', { variant: 'error' });
    }
  };
export const getVehicleAndRevenueReportAnalysisAction =
  (enqueueSnackbar, startDate = '', endDate = '') =>
  async (dispatch) => {
    dispatch({ type: SettingsTypes.LOADING_SETTINGS });

    try {
      const result = await httpRequest({
        url: `Dashboard/VehicleAndRevenueReportAnalysis${
          startDate ? `?StartDate=${startDate}&EndDate=${endDate}` : ''
        }`,
        urlType: 'settings',
      });

      if (result.code === 1) {
        dispatch({ type: SettingsTypes.VEHICLE_REVENUE_REPORT, payload: result?.data });
      }

      if (result.code !== 1) {
        dispatch({ type: SettingsTypes.LISTING_RESET });
        if (result.shortDescription) enqueueSnackbar(result.shortDescription, { variant: 'error' });
      }
    } catch (error) {
      dispatch({ type: SettingsTypes.LISTING_RESET });
      enqueueSnackbar(error?.message || 'Unable to fetch data! Please refresh and try again', { variant: 'error' });
    }
  };
export const getDailyPerformanceReportAction =
  (enqueueSnackbar, startDate = '', endDate = '') =>
  async (dispatch) => {
    dispatch({ type: SettingsTypes.LOADING_SETTINGS });

    try {
      const result = await httpRequest({
        url: `Dashboard/DailyPerformanceReport${startDate ? `?StartDate=${startDate}&EndDate=${endDate}` : ''}`,
        // url: filteredURL,
        urlType: 'settings',
      });
      if (result.code === 1) {
        dispatch({ type: SettingsTypes.DAILY_PERFORMANCE_REPORT, payload: result?.data });
      }

      if (result.code !== 1) {
        dispatch({ type: SettingsTypes.LISTING_RESET });
        if (result.shortDescription) enqueueSnackbar(result.shortDescription, { variant: 'error' });
      }
    } catch (error) {
      dispatch({ type: SettingsTypes.LISTING_RESET });
      enqueueSnackbar(error?.message || 'Unable to fetch data! Please refresh and try again', { variant: 'error' });
    }
  };
export const getDepartmentByAction = (enqueueSnackbar) => async (dispatch) => {
  dispatch({ type: SettingsTypes.FETCHING_SETTINGS });

  try {
    const result = await httpRequest({
      url: `Departments`,
      urlType: 'settings',
    });

    if (result.code === 1) {
      dispatch({ type: SettingsTypes.DEPARTMENT_LIST, payload: result?.data });
    }

    if (result.code !== 1) {
      dispatch({ type: SettingsTypes.LISTING_RESET });
      if (result.shortDescription) enqueueSnackbar(result.shortDescription, { variant: 'error' });
    }
  } catch (error) {
    dispatch({ type: SettingsTypes.LISTING_RESET });
    enqueueSnackbar(error?.message || 'Unable to fetch data! Please refresh and try again', { variant: 'error' });
  }
};
export const getCampusAmbassadorAction =
  (
    enqueueSnackbar,
    startDate = '',
    endDate = '',
    parameter = '',
    value = '',
    pageNumber = 1,
    pageSize = 50,
    paginated = false
  ) =>
  async (dispatch) => {
    dispatch({ type: SettingsTypes.LOADING_SETTINGS });
    let filteredURL = `Accounts/GetAmbassadors?Descending=true`;
    if (parameter) filteredURL = `${filteredURL}&Search=${value}&Parameter=${parameter}`;
    if (startDate) filteredURL = `${filteredURL}&StartDate=${startDate}`;
    if (endDate) filteredURL = `${filteredURL}&EndDate=${endDate}`;
    if (paginated) filteredURL = `${filteredURL}&PageNumber=${pageNumber}&PageSize=${pageSize}&Paginated=${paginated}`;

    try {
      const result = await httpRequest({
        url: filteredURL,
        urlType: 'ambassador',
      });

      if (result.code === 1) {
        dispatch({ type: SettingsTypes.CAMPUS_AMBASSADOR_LIST, payload: result?.data });
      }

      if (result.code !== 1) {
        dispatch({ type: SettingsTypes.LISTING_RESET });
        if (result.shortDescription) enqueueSnackbar(result.shortDescription, { variant: 'error' });
      }
    } catch (error) {
      dispatch({ type: SettingsTypes.LISTING_RESET });
      enqueueSnackbar(error?.message || 'Unable to fetch data! Please refresh and try again', { variant: 'error' });
    }
  };

export const getAmbassadorTicketBookingAction =
  (enqueueSnackbar, startDate = '', endDate = '', pageNumber = 1, pageSize = 50, paginated = false) =>
  async (dispatch) => {
    dispatch({ type: SettingsTypes.LOADING_SETTINGS });
    let filteredURL = `Bookings/Histories?Descending=true`;
    if (startDate) filteredURL = `${filteredURL}&StartDate=${startDate}`;
    if (endDate) filteredURL = `${filteredURL}&EndDate=${endDate}`;
    if (paginated) filteredURL = `${filteredURL}&PageNumber=${pageNumber}&PageSize=${pageSize}&Paginated=${paginated}`;

    try {
      const result = await httpRequest({
        url: filteredURL,
        urlType: 'ambassador',
      });

      if (result.code === 1) {
        dispatch({ type: SettingsTypes.AMBASSADOR_TICKET_BOOKING, payload: result?.data });
      }

      if (result.code !== 1) {
        dispatch({ type: SettingsTypes.LISTING_RESET });
        if (result.shortDescription) enqueueSnackbar(result.shortDescription, { variant: 'error' });
      }
    } catch (error) {
      dispatch({ type: SettingsTypes.LISTING_RESET });
      enqueueSnackbar(error?.message || 'Unable to fetch data! Please refresh and try again', { variant: 'error' });
    }
  };

export const getAmbassadorBookingHistoryAction =
  (enqueueSnackbar, email = '', startDate = '', endDate = '', pageNumber = 1, pageSize = 50, paginated = false) =>
  async (dispatch) => {
    dispatch({ type: SettingsTypes.LOADING_SETTINGS });
    let filteredURL = `Accounts/AmbassadorBookingHistory?Email=${email}&Descending=true`;
    if (startDate) filteredURL = `${filteredURL}&StartDate=${startDate}`;
    if (endDate) filteredURL = `${filteredURL}&EndDate=${endDate}`;
    if (paginated) filteredURL = `${filteredURL}&PageNumber=${pageNumber}&PageSize=${pageSize}&Paginated=${paginated}`;

    try {
      const result = await httpRequest({
        url: filteredURL,
        urlType: 'ambassador',
      });

      if (result.code === 1) {
        dispatch({ type: SettingsTypes.AMBASSADOR_TICKET_BOOKING, payload: result?.data });
      }

      if (result.code !== 1) {
        dispatch({ type: SettingsTypes.LISTING_RESET });
        if (result.shortDescription) enqueueSnackbar(result.shortDescription, { variant: 'error' });
      }
    } catch (error) {
      dispatch({ type: SettingsTypes.LISTING_RESET });
      enqueueSnackbar(error?.message || 'Unable to fetch data! Please refresh and try again', { variant: 'error' });
    }
  };

export const getAllAmbassadorTransactionAction =
  (
    enqueueSnackbar,
    startDate = '',
    endDate = '',
    paymentStatus = '',
    parameter = '',
    value = '',
    pageNumber = 1,
    pageSize = 50,
    paginated = false
  ) =>
  async (dispatch) => {
    dispatch({ type: SettingsTypes.LOADING_SETTINGS });
    let filteredURL = `Withdrawal/GetAllTransactions?Descending=true`;
    if (paymentStatus) filteredURL = `${filteredURL}&PaymentStatus=${paymentStatus}`;
    if (startDate) filteredURL = `${filteredURL}&StartDate=${startDate}`;
    if (endDate) filteredURL = `${filteredURL}&EndDate=${endDate}`;
    if (parameter) filteredURL = `${filteredURL}&Search=${value}&Parameter=${parameter}`;
    if (paginated) filteredURL = `${filteredURL}&PageNumber=${pageNumber}&PageSize=${pageSize}&Paginated=${paginated}`;

    try {
      const result = await httpRequest({
        url: filteredURL,
        urlType: 'ambassador',
      });

      if (result.code === 1) {
        dispatch({ type: SettingsTypes.AMBASSADOR_TRANSACTIONS, payload: result?.data });
      }

      if (result.code !== 1) {
        dispatch({ type: SettingsTypes.LISTING_RESET });
        if (result.shortDescription) enqueueSnackbar(result.shortDescription, { variant: 'error' });
      }
    } catch (error) {
      dispatch({ type: SettingsTypes.LISTING_RESET });
      enqueueSnackbar(error?.message || 'Unable to fetch data! Please refresh and try again', { variant: 'error' });
    }
  };
export const getAmbassadorWithdrawalRequestAction =
  (
    enqueueSnackbar,
    startDate = '',
    endDate = '',
    paymentStatus = '',
    parameter = '',
    value = '',
    pageNumber = 1,
    pageSize = 50,
    paginated = false
  ) =>
  async (dispatch) => {
    dispatch({ type: SettingsTypes.LOADING_SETTINGS });
    let filteredURL = `Withdrawal/WithdrawalRequests?Descending=true`;
    if (paymentStatus) filteredURL = `${filteredURL}&PaymentStatus=${paymentStatus}`;
    if (startDate) filteredURL = `${filteredURL}&StartDate=${startDate}`;
    if (endDate) filteredURL = `${filteredURL}&EndDate=${endDate}`;
    if (parameter) filteredURL = `${filteredURL}&Search=${value}&Parameter=${parameter}`;
    if (paginated) filteredURL = `${filteredURL}&PageNumber=${pageNumber}&PageSize=${pageSize}&Paginated=${paginated}`;

    try {
      const result = await httpRequest({
        url: filteredURL,
        urlType: 'ambassador',
      });

      if (result.code === 1) {
        dispatch({ type: SettingsTypes.AMBASSADOR_WITHDRAWAL_REQUEST, payload: result?.data });
      }

      if (result.code !== 1) {
        dispatch({ type: SettingsTypes.LISTING_RESET });
        if (result.shortDescription) enqueueSnackbar(result.shortDescription, { variant: 'error' });
      }
    } catch (error) {
      dispatch({ type: SettingsTypes.LISTING_RESET });
      enqueueSnackbar(error?.message || 'Unable to fetch data! Please refresh and try again', { variant: 'error' });
    }
  };
export const searchAmbassadorWithdrawalRequestAction =
  (
    enqueueSnackbar,
    // startDate = '',
    // endDate = '',
    // paymentStatus = '',
    parameter = '',
    value = '',
    pageNumber = 1,
    pageSize = 50,
    paginated = false
  ) =>
  async (dispatch) => {
    dispatch({ type: SettingsTypes.LOADING_SETTINGS });
    let filteredURL = `Withdrawal/SearchAmbassadorWithdrawalHistory?Descending=true`;
    // if (paymentStatus) filteredURL = `${filteredURL}&PaymentStatus=${paymentStatus}`;
    // if (startDate) filteredURL = `${filteredURL}&StartDate=${startDate}`;
    // if (endDate) filteredURL = `${filteredURL}&EndDate=${endDate}`;
    if (parameter) filteredURL = `${filteredURL}&Search=${value}&Parameter=${parameter}`;
    if (paginated) filteredURL = `${filteredURL}&PageNumber=${pageNumber}&PageSize=${pageSize}&Paginated=${paginated}`;

    try {
      const result = await httpRequest({
        url: filteredURL,
        urlType: 'ambassador',
      });

      if (result.code === 1) {
        dispatch({ type: SettingsTypes.AMBASSADOR_WITHDRAWAL_REQUEST, payload: result?.data });
      }

      if (result.code !== 1) {
        dispatch({ type: SettingsTypes.LISTING_RESET });
        if (result.shortDescription) enqueueSnackbar(result.shortDescription, { variant: 'error' });
      }
    } catch (error) {
      dispatch({ type: SettingsTypes.LISTING_RESET });
      enqueueSnackbar(error?.message || 'Unable to fetch data! Please refresh and try again', { variant: 'error' });
    }
  };

export const getAmbassadorByIdAction =
  (enqueueSnackbar, ambassadorId = '') =>
  async (dispatch) => {
    dispatch({ type: SettingsTypes.LOADING_SETTINGS });
    try {
      const result = await httpRequest({
        url: `Accounts/GetAmbassador?Id=${ambassadorId}`,
        urlType: 'ambassador',
      });

      if (result.code === 1) {
        dispatch({ type: SettingsTypes.CAMPUS_AMBASSADOR_DETAIL, payload: result?.data });
      }

      if (result.code !== 1) {
        dispatch({ type: SettingsTypes.LISTING_RESET });
        if (result.shortDescription) enqueueSnackbar(result.shortDescription, { variant: 'error' });
      }
    } catch (error) {
      dispatch({ type: SettingsTypes.LISTING_RESET });
      enqueueSnackbar(error?.message || 'Unable to fetch data! Please refresh and try again', { variant: 'error' });
    }
  };

export const getVehicleAction =
  (enqueueSnackbar, status = 1000, paginated = false, pageNumber = 1, pageSize = 50) =>
  async (dispatch) => {
    dispatch({ type: SettingsTypes.FETCHING_SETTINGS });
    let filteredURL = `Vehicle?VehicleStatus=${status}&Descending=true&Paginated=${paginated}`;
    if (paginated) filteredURL = `${filteredURL}&pageNumber=${pageNumber}&pageSize=${pageSize}`;

    try {
      const result = await httpRequest({
        url: filteredURL,
        urlType: 'settings',
      });

      if (result.code === 1) {
        dispatch({ type: SettingsTypes.GET_VEHICLE_LIST, payload: result?.data });
      }

      if (result.code !== 1) {
        dispatch({ type: SettingsTypes.LISTING_RESET });
        if (result.shortDescription) enqueueSnackbar(result.shortDescription, { variant: 'error' });
      }
    } catch (error) {
      dispatch({ type: SettingsTypes.LISTING_RESET });
      enqueueSnackbar(error?.message || 'Unable to fetch data! Please refresh and try again', { variant: 'error' });
    }
  };

export const getVehicleByIdAction =
  (enqueueSnackbar, vehicleId = '') =>
  async (dispatch) => {
    dispatch({ type: SettingsTypes.LOADING_SETTINGS });
    try {
      const result = await httpRequest({
        url: `Vehicle/${vehicleId}`,
        urlType: 'settings',
      });

      if (result.code === 1) {
        dispatch({ type: SettingsTypes.GET_VEHICLE_LIST_ID, payload: result?.data });
      }

      if (result.code !== 1) {
        dispatch({ type: SettingsTypes.LISTING_RESET });
        if (result.shortDescription) enqueueSnackbar(result.shortDescription, { variant: 'error' });
      }
    } catch (error) {
      dispatch({ type: SettingsTypes.LISTING_RESET });
      enqueueSnackbar(error?.message || 'Unable to fetch data! Please refresh and try again', { variant: 'error' });
    }
  };

export const getStateByAction =
  (enqueueSnackbar, pageNumber = 1, pageSize = 50, paginated = false) =>
  async (dispatch) => {
    dispatch({ type: SettingsTypes.LOADING_SETTINGS });
    let filteredURL = `State?Paginated=${paginated}`;
    if (paginated) filteredURL = `${filteredURL}&PageNumber=${pageNumber}&PageSize=${pageSize}&Paginated=${paginated}`;

    try {
      const result = await httpRequest({
        url: filteredURL,
        urlType: 'settings',
      });

      if (result.code === 1) {
        dispatch({ type: SettingsTypes.GET_STATE_LIST, payload: result?.data });
      }

      if (result.code !== 1) {
        dispatch({ type: SettingsTypes.LISTING_RESET });
        if (result.shortDescription) enqueueSnackbar(result.shortDescription, { variant: 'error' });
      }
    } catch (error) {
      dispatch({ type: SettingsTypes.LISTING_RESET });
      enqueueSnackbar(error?.message || 'Unable to fetch data! Please refresh and try again', { variant: 'error' });
    }
  };

export const assignPrimaryCaptainAction = (enqueueSnackbar) => async (dispatch) => {
  dispatch({ type: SettingsTypes.LOADING_SETTINGS });
  try {
    const result = await httpRequest({
      url: `Vehicle/assigncaptain`,
      urlType: 'settings',
    });
    if (result.code === 1) {
      dispatch({ type: SettingsTypes.ASSIGN_PRIMARY_CAPTAIN, payload: result?.data });
    }

    if (result.code !== 1) {
      dispatch({ type: SettingsTypes.LISTING_RESET });
      if (result.shortDescription) enqueueSnackbar(result.shortDescription, { variant: 'error' });
    }
  } catch (error) {
    dispatch({ type: SettingsTypes.LISTING_RESET });
    enqueueSnackbar(error?.message || 'Unable to fetch data! Please refresh and try again', { variant: 'error' });
  }
};

export const getVehicleModelAction = (enqueueSnackbar) => async (dispatch) => {
  dispatch({ type: SettingsTypes.LOADING_SETTINGS });
  try {
    const result = await httpRequest({
      url: `Vehicle/models`,
      urlType: 'settings',
    });

    if (result.code === 1) {
      dispatch({ type: SettingsTypes.GET_VEHICLE_MODEL_LIST, payload: result?.data });
    }

    if (result.code !== 1) {
      dispatch({ type: SettingsTypes.LISTING_RESET });
      if (result.shortDescription) enqueueSnackbar(result.shortDescription, { variant: 'error' });
    }
  } catch (error) {
    dispatch({ type: SettingsTypes.LISTING_RESET });
    enqueueSnackbar(error?.message || 'Unable to fetch data! Please refresh and try again', { variant: 'error' });
  }
};

export const vehicleMakeAction = (enqueueSnackbar) => async (dispatch) => {
  dispatch({ type: SettingsTypes.LOADING_SETTINGS });
  try {
    const result = await httpRequest({
      url: `Vehicle/makes`,
      urlType: 'settings',
    });

    if (result.code === 1) {
      dispatch({ type: SettingsTypes.VEHICLE_MAKE_LISTING, payload: result?.data });
    }

    if (result.code !== 1) {
      dispatch({ type: SettingsTypes.LISTING_RESET });
      if (result.shortDescription) enqueueSnackbar(result.shortDescription, { variant: 'error' });
    }
  } catch (error) {
    dispatch({ type: SettingsTypes.LISTING_RESET });
    enqueueSnackbar(error?.message || 'Unable to fetch data! Please refresh and try again', { variant: 'error' });
  }
};

export const vehicleFeaturesAction = (enqueueSnackbar) => async (dispatch) => {
  dispatch({ type: SettingsTypes.LOADING_SETTINGS });
  try {
    const result = await httpRequest({
      url: `Vehicle/features`,
      urlType: 'settings',
    });

    if (result.code === 1) {
      dispatch({ type: SettingsTypes.VEHICLE_FEATURES_LISTING, payload: result?.data });
    }

    if (result.code !== 1) {
      dispatch({ type: SettingsTypes.LISTING_RESET });
      if (result.shortDescription) enqueueSnackbar(result.shortDescription, { variant: 'error' });
    }
  } catch (error) {
    dispatch({ type: SettingsTypes.LISTING_RESET });
    enqueueSnackbar(error?.message || 'Unable to fetch data! Please refresh and try again', { variant: 'error' });
  }
};
// All Captain Actions
//
//
export const getCaptainRecordByAction =
  (enqueueSnackbar, getBy = '') =>
  async (dispatch) => {
    dispatch({ type: SettingsTypes.LOADING_SETTINGS });
    try {
      const result = await httpRequest({
        url: `Captains/statusHistory/${getBy}`,
        urlType: 'captains',
      });

      if (result.code === 1) {
        dispatch({ type: SettingsTypes.CAPTAIN_RECORD, payload: result?.data });
      }

      if (result.code !== 1) {
        dispatch({ type: SettingsTypes.LISTING_RESET });
        if (result.shortDescription) enqueueSnackbar(result.shortDescription, { variant: 'error' });
      }
    } catch (error) {
      dispatch({ type: SettingsTypes.LISTING_RESET });
      enqueueSnackbar(error?.message || 'Unable to fetch data! Please refresh and try again', { variant: 'error' });
    }
  };

export const getCaptainTripHistoryByAction =
  (enqueueSnackbar, id = '') =>
  async (dispatch) => {
    dispatch({ type: SettingsTypes.LOADING_SETTINGS });
    try {
      const result = await httpRequest({
        url: `Captains/triphistory/${id}`,
        urlType: 'captains',
      });

      if (result.code === 1) {
        dispatch({ type: SettingsTypes.CAPTAIN_TRIP_HISTORY, payload: result?.data });
      }

      if (result.code !== 1) {
        dispatch({ type: SettingsTypes.LISTING_RESET });
        if (result.shortDescription) enqueueSnackbar(result.shortDescription, { variant: 'error' });
      }
    } catch (error) {
      dispatch({ type: SettingsTypes.LISTING_RESET });
      enqueueSnackbar(error?.message || 'Unable to fetch data! Please refresh and try again', { variant: 'error' });
    }
  };

export const getCaptainByAction =
  (enqueueSnackbar, getBy = '') =>
  async (dispatch) => {
    dispatch({ type: SettingsTypes.LOADING_SETTINGS });
    try {
      const result = await httpRequest({
        url: `Captains/${getBy}`,
        urlType: 'captains',
      });

      if (result.code === 1) {
        dispatch({ type: SettingsTypes.CAPTAIN_LISTING, payload: result?.data });
      }

      if (result.code !== 1) {
        dispatch({ type: SettingsTypes.LISTING_RESET });
        if (result.shortDescription) enqueueSnackbar(result.shortDescription, { variant: 'error' });
      }
    } catch (error) {
      dispatch({ type: SettingsTypes.LISTING_RESET });
      enqueueSnackbar(error?.message || 'Unable to fetch data! Please refresh and try again', { variant: 'error' });
    }
  };

export const getCaptainByStatusAction = (enqueueSnackbar) => async (dispatch) => {
  dispatch({ type: SettingsTypes.LOADING_SETTINGS });
  try {
    const result = await httpRequest({
      url: `Captains?CaptainStatus=0&Descending=true`,
      urlType: 'captains',
    });

    if (result.code === 1) {
      dispatch({ type: SettingsTypes.CAPTAIN_LISTING, payload: result?.data });
    }

    if (result.code !== 1) {
      dispatch({ type: SettingsTypes.LISTING_RESET });
      if (result.shortDescription) enqueueSnackbar(result.shortDescription, { variant: 'error' });
    }
  } catch (error) {
    dispatch({ type: SettingsTypes.LISTING_RESET });
    enqueueSnackbar(error?.message || 'Unable to fetch data! Please refresh and try again', { variant: 'error' });
  }
};

export const getPrimaryCaptainByAction = (enqueueSnackbar) => async (dispatch) => {
  dispatch({ type: SettingsTypes.LOADING_SETTINGS });
  try {
    const result = await httpRequest({
      url: `Captains/primarycaptains`,
      urlType: 'captains',
    });

    if (result.code === 1) {
      dispatch({ type: SettingsTypes.GET_PRIMARY_CAPTAIN, payload: result?.data });
    }

    if (result.code !== 1) {
      dispatch({ type: SettingsTypes.LISTING_RESET });
      if (result.shortDescription) enqueueSnackbar(result.shortDescription, { variant: 'error' });
    }
  } catch (error) {
    dispatch({ type: SettingsTypes.LISTING_RESET });
    enqueueSnackbar(error?.message || 'Unable to fetch data! Please refresh and try again', { variant: 'error' });
  }
};

export const getCaptainClassificationByAction = (enqueueSnackbar) => async (dispatch) => {
  dispatch({ type: SettingsTypes.LOADING_SETTINGS });
  try {
    const result = await httpRequest({
      url: `CaptainClassification`,
      urlType: 'captains',
    });

    if (result.code === 1) {
      dispatch({ type: SettingsTypes.CAPTAIN_CLASSIFICATION_LIST, payload: result?.data });
    }

    if (result.code !== 1) {
      dispatch({ type: SettingsTypes.LISTING_RESET });
      if (result.shortDescription) enqueueSnackbar(result.shortDescription, { variant: 'error' });
    }
  } catch (error) {
    dispatch({ type: SettingsTypes.LISTING_RESET });
    enqueueSnackbar(error?.message || 'Unable to fetch data! Please refresh and try again', { variant: 'error' });
  }
};

export const getLockedOutCaptainByAction =
  (enqueueSnackbar, pageNumber = 1, pageSize = 50, paginated = false) =>
  async (dispatch) => {
    dispatch({ type: SettingsTypes.LOADING_SETTINGS });
    let filteredURL = `captaintrip/getAllLockedCaptain?Descending=true`;
    if (paginated) filteredURL = `${filteredURL}&PageNumber=${pageNumber}&PageSize=${pageSize}&Paginated=${paginated}`;

    try {
      const result = await httpRequest({
        url: filteredURL,
        urlType: 'captains',
      });

      if (result.code === 1) {
        dispatch({ type: SettingsTypes.LOCKED_OUT_CAPTAINS, payload: result?.data });
      }

      if (result.code !== 1) {
        dispatch({ type: SettingsTypes.LISTING_RESET });
        if (result.shortDescription) enqueueSnackbar(result.shortDescription, { variant: 'error' });
      }
    } catch (error) {
      dispatch({ type: SettingsTypes.LISTING_RESET });
      enqueueSnackbar(error?.message || 'Unable to fetch data! Please refresh and try again', { variant: 'error' });
    }
  };
export const getSuspendedInactiveCaptainByAction =
  (enqueueSnackbar, pageNumber = 1, pageSize = 50, paginated = false) =>
  async (dispatch) => {
    dispatch({ type: SettingsTypes.LOADING_SETTINGS });
    let filteredURL = `Captains/GetAllSuspended2MonthsInActiveCaptains?Descending=true`;
    if (paginated) filteredURL = `${filteredURL}&PageNumber=${pageNumber}&PageSize=${pageSize}&Paginated=${paginated}`;

    try {
      const result = await httpRequest({
        url: filteredURL,
        urlType: 'captains',
      });

      if (result.code === 1) {
        dispatch({ type: SettingsTypes.SUSPENDED_INACTIVE_CAPTAINS, payload: result?.data });
      }

      if (result.code !== 1) {
        dispatch({ type: SettingsTypes.LISTING_RESET });
        if (result.shortDescription) enqueueSnackbar(result.shortDescription, { variant: 'error' });
      }
    } catch (error) {
      dispatch({ type: SettingsTypes.LISTING_RESET });
      enqueueSnackbar(error?.message || 'Unable to fetch data! Please refresh and try again', { variant: 'error' });
    }
  };
export const getAllCaptainByAction =
  (
    enqueueSnackbar,
    parameter = '',
    value = '',
    captainType = 1000,
    pageNumber = 1,
    pageSize = 50,
    paginated = false,
    useFetching = false,
    isReport = false
  ) =>
  async (dispatch) => {
    if (useFetching) {
      dispatch({ type: SettingsTypes.FETCHING_SETTINGS });
    } else {
      dispatch({ type: SettingsTypes.LOADING_SETTINGS });
    }
    let filteredURL = `Captains?CaptainStatus=1000&CaptainType=${captainType}&Descending=true&Paginated=${paginated}`;
    if (paginated) filteredURL = `${filteredURL}&PageNumber=${pageNumber}&PageSize=${pageSize}`;
    if (parameter) filteredURL = `${filteredURL}&Search=${value}&Parameter=${parameter}`;
    if (isReport) filteredURL = `${filteredURL}&IsReport=${isReport}`;

    try {
      const result = await httpRequest({
        url: filteredURL,
        urlType: 'captains',
      });

      if (result.code === 1) {
        if (isReport) {
          handleConvertByte(result?.data?.reportData, result?.data?.reportName);
          dispatch({ type: SettingsTypes.LISTING_RESET });
        } else {
          dispatch({ type: SettingsTypes.GET_ALL_CAPTAIN_LISTING, payload: result?.data });
        }
      }

      if (result.code !== 1) {
        dispatch({ type: SettingsTypes.LISTING_RESET });
        if (result.shortDescription) enqueueSnackbar(result.shortDescription, { variant: 'error' });
      }
    } catch (error) {
      dispatch({ type: SettingsTypes.LISTING_RESET });
      enqueueSnackbar(error?.message || 'Unable to fetch data! Please refresh and try again', { variant: 'error' });
    }
  };

export const getAllActiveCaptainByAction =
  (enqueueSnackbar, captainStatus = 1000, captainType = 1000, pageNumber = 1, pageSize = 50, paginated = false) =>
  async (dispatch) => {
    dispatch({ type: SettingsTypes.LOADING_SETTINGS });
    let filteredURL = `Captains?CaptainStatus=${captainStatus}&CaptainType=${captainType}&Descending=true&Paginated=${paginated}`;
    if (paginated) filteredURL = `${filteredURL}&PageNumber=${pageNumber}&PageSize=${pageSize}`;

    try {
      const result = await httpRequest({
        url: filteredURL,
        urlType: 'captains',
      });

      if (result.code === 1) {
        dispatch({ type: SettingsTypes.GET_ALL_CAPTAIN_LISTING, payload: result?.data });
      }

      if (result.code !== 1) {
        dispatch({ type: SettingsTypes.LISTING_RESET });
        if (result.shortDescription) enqueueSnackbar(result.shortDescription, { variant: 'error' });
      }
    } catch (error) {
      dispatch({ type: SettingsTypes.LISTING_RESET });
      enqueueSnackbar(error?.message || 'Unable to fetch data! Please refresh and try again', { variant: 'error' });
    }
  };

export const captainByVehicleRegNoAction = (enqueueSnackbar, vehRegNo) => async (dispatch) => {
  dispatch({ type: SettingsTypes.LOADING_SETTINGS });
  try {
    const result = await httpRequest({
      url: `Captains/captainbyvehicleregno?vehicleRegNo=${vehRegNo}`,
      urlType: 'captains',
    });

    if (result.code === 1) {
      dispatch({ type: SettingsTypes.VEHICLE_IN_TERMINAL_LIST, payload: result?.data });
    }

    if (result.code !== 1) {
      dispatch({ type: SettingsTypes.LISTING_RESET });
      if (result.shortDescription) enqueueSnackbar(result.shortDescription, { variant: 'error' });
    }
  } catch (error) {
    dispatch({ type: SettingsTypes.LISTING_RESET });
    enqueueSnackbar(error?.message || 'Unable to fetch data! Please refresh and try again', { variant: 'error' });
  }
};

export const getCaptainByType =
  (enqueueSnackbar, type = '') =>
  async (dispatch) => {
    // dispatch({ type: SettingsTypes.LOADING_SETTINGS });
    try {
      const result = await httpRequest({
        url: `Captains?CaptainType=${type}&Paginated=false`,
        urlType: 'captains',
      });
      if (result.code === 1) {
        if (type === 0) dispatch({ type: SettingsTypes.GET_PRIMARY_CAPTAIN, payload: result?.data?.userData });
        if (type === 1) dispatch({ type: SettingsTypes.GET_HAND_OVER_CAPTAIN, payload: result?.data?.userData });
      }

      if (result.code !== 1) {
        dispatch({ type: SettingsTypes.LISTING_RESET });
        if (result.shortDescription) enqueueSnackbar(result.shortDescription, { variant: 'error' });
      }
    } catch (error) {
      dispatch({ type: SettingsTypes.LISTING_RESET });
      enqueueSnackbar(error?.message || 'Unable to fetch data! Please refresh and try again', { variant: 'error' });
    }
  };

export const getHandoverCaptainAction =
  (enqueueSnackbar, vehicleId = '') =>
  async (dispatch) => {
    // dispatch({ vehicleId: SettingsTypes.LOADING_SETTINGS });
    try {
      const result = await httpRequest({
        url: `Vehicle/captain/${vehicleId}`,
        urlType: 'settings',
      });
      if (result.code === 1) {
        dispatch({ type: SettingsTypes.HAND_OVER_CAPTAIN_LIST, payload: result?.data });
      }

      if (result.code !== 1) {
        dispatch({ type: SettingsTypes.LISTING_RESET });
        if (result.shortDescription) enqueueSnackbar(result.shortDescription, { variant: 'error' });
      }
    } catch (error) {
      dispatch({ type: SettingsTypes.LISTING_RESET });
      enqueueSnackbar(error?.message || 'Unable to fetch data! Please refresh and try again', { variant: 'error' });
    }
  };

export const getBankByAction = (enqueueSnackbar) => async (dispatch) => {
  dispatch({ type: SettingsTypes.LOADING_SETTINGS });
  try {
    const result = await httpRequest({
      url: `Banks`,
      urlType: 'settings',
    });

    if (result.code === 1) {
      dispatch({ type: SettingsTypes.BANK_LIST, payload: result?.data });
    }

    if (result.code !== 1) {
      dispatch({ type: SettingsTypes.LISTING_RESET });
      if (result.shortDescription) enqueueSnackbar(result.shortDescription, { variant: 'error' });
    }
  } catch (error) {
    dispatch({ type: SettingsTypes.LISTING_RESET });
    enqueueSnackbar(error?.message || 'Unable to fetch data! Please refresh and try again', { variant: 'error' });
  }
};

export const getRouteTypeAction = (enqueueSnackbar) => async (dispatch) => {
  try {
    const result = await httpRequest({
      url: `RouteTypes`,
      urlType: 'settings',
    });

    if (result.code === 1) {
      dispatch({ type: SettingsTypes.ROUTE_TYPE_LIST, payload: result?.data });
    }

    if (result.code !== 1) {
      dispatch({ type: SettingsTypes.LISTING_RESET });
      if (result.shortDescription) enqueueSnackbar(result.shortDescription, { variant: 'error' });
    }
  } catch (error) {
    dispatch({ type: SettingsTypes.LISTING_RESET });
    enqueueSnackbar(error?.message || 'Unable to fetch data! Please refresh and try again', { variant: 'error' });
  }
};

export const getDocumentTypeAction = (enqueueSnackbar) => async (dispatch) => {
  try {
    const result = await httpRequest({
      url: `TravelDocument?Paginated=false`,
      urlType: 'settings',
    });

    if (result.code === 1) {
      dispatch({ type: SettingsTypes.TRAVEL_DOC_TYPE_LIST, payload: result?.data });
    }

    if (result.code !== 1) {
      dispatch({ type: SettingsTypes.LISTING_RESET });
      if (result.shortDescription) enqueueSnackbar(result.shortDescription, { variant: 'error' });
    }
  } catch (error) {
    dispatch({ type: SettingsTypes.LISTING_RESET });
    enqueueSnackbar(error?.message || 'Unable to fetch data! Please refresh and try again', { variant: 'error' });
  }
};

export const getDocumentTypeWithAmountAction = (enqueueSnackbar) => async (dispatch) => {
  try {
    const result = await httpRequest({
      url: `TravelDocumentFee?Paginated=false`,
      urlType: 'settings',
    });

    if (result.code === 1) {
      dispatch({ type: SettingsTypes.TRAVEL_DOC_TYPE_WITH_AMOUNT_LIST, payload: result?.data });
    }

    if (result.code !== 1) {
      dispatch({ type: SettingsTypes.LISTING_RESET });
      if (result.shortDescription) enqueueSnackbar(result.shortDescription, { variant: 'error' });
    }
  } catch (error) {
    dispatch({ type: SettingsTypes.LISTING_RESET });
    enqueueSnackbar(error?.message || 'Unable to fetch data! Please refresh and try again', { variant: 'error' });
  }
};

export const getTerminalAction =
  (enqueueSnackbar, pageNumber = 1, pageSize = 50, paginated = false, avialableOnLine = 1000) =>
  async (dispatch) => {
    dispatch({ type: SettingsTypes.LOADING_SETTINGS });
    // let filteredURL = `Terminal?Paginated=${paginated}`;
    let filteredURL = `Terminal?AvialableOnLine=${avialableOnLine}&Descending=false&Paginated=${paginated}`;
    if (paginated) filteredURL = `${filteredURL}&PageNumber=${pageNumber}&PageSize=${pageSize}&Paginated=${paginated}`;

    try {
      const result = await httpRequest({
        url: filteredURL,
        urlType: 'settings',
      });

      if (result.code === 1) {
        dispatch({ type: SettingsTypes.GET_TERMINAL_LIST, payload: result?.data });
      }

      if (result.code !== 1) {
        dispatch({ type: SettingsTypes.LISTING_RESET });
        if (result.shortDescription) enqueueSnackbar(result.shortDescription, { variant: 'error' });
      }
    } catch (error) {
      dispatch({ type: SettingsTypes.LISTING_RESET });
      enqueueSnackbar(error?.message || 'Unable to fetch data! Please refresh and try again', { variant: 'error' });
    }
  };
export const getBannerAction = (enqueueSnackbar) => async (dispatch) => {
  dispatch({ type: SettingsTypes.LOADING_SETTINGS });

  try {
    const result = await httpRequest({
      url: `Banner`,
      urlType: 'settings',
    });

    if (result.code === 1) {
      dispatch({ type: SettingsTypes.BANNER_LIST, payload: result?.data });
    }

    if (result.code !== 1) {
      dispatch({ type: SettingsTypes.LISTING_RESET });
      if (result.shortDescription) enqueueSnackbar(result.shortDescription, { variant: 'error' });
    }
  } catch (error) {
    dispatch({ type: SettingsTypes.LISTING_RESET });
    enqueueSnackbar(error?.message || 'Unable to fetch data! Please refresh and try again', { variant: 'error' });
  }
};
export const getAzureBugLogAction = (enqueueSnackbar) => async (dispatch) => {
  dispatch({ type: SettingsTypes.LOADING_SETTINGS });

  try {
    const result = await httpRequest({
      url: `AzureDevops/bugs`,
      urlType: 'settings',
    });

    if (result.code === 1) {
      dispatch({ type: SettingsTypes.AZURE_BUG_LOG, payload: result?.data });
    }

    if (result.code !== 1) {
      dispatch({ type: SettingsTypes.LISTING_RESET });
      if (result.shortDescription) enqueueSnackbar(result.shortDescription, { variant: 'error' });
    }
  } catch (error) {
    dispatch({ type: SettingsTypes.LISTING_RESET });
    enqueueSnackbar(error?.message || 'Unable to fetch data! Please refresh and try again', { variant: 'error' });
  }
};

export const getAzureBugLogDetailAction = (enqueueSnackbar, id) => async (dispatch) => {
  dispatch({ type: SettingsTypes.LOADING_SETTINGS });

  try {
    const result = await httpRequest({
      url: `AzureDevops/bug/${id}`,
      urlType: 'settings',
    });

    if (result.code === 1) {
      dispatch({ type: SettingsTypes.AZURE_BUG_LOG_BY_ID, payload: result?.data });
    }

    if (result.code !== 1) {
      dispatch({ type: SettingsTypes.LISTING_RESET });
      if (result.shortDescription) enqueueSnackbar(result.shortDescription, { variant: 'error' });
    }
  } catch (error) {
    dispatch({ type: SettingsTypes.LISTING_RESET });
    enqueueSnackbar(error?.message || 'Unable to fetch data! Please refresh and try again', { variant: 'error' });
  }
};

export const getDataBaseLogAction =
  (enqueueSnackbar, entityName = '', pageNumber = 1, pageSize = 50, paginated = false, isReport = false) =>
  async (dispatch) => {
    dispatch({ type: SettingsTypes.LOADING_SETTINGS });
    //  `DatabaseLog/Databaselog?EntityName=${users}`
    let filteredURL = `DatabaseLog/Databaselog?Descending=true`;
    if (paginated) filteredURL = `${filteredURL}&PageNumber=${pageNumber}&PageSize=${pageSize}&Paginated=${paginated}`;
    if (entityName) filteredURL = `${filteredURL}&EntityName=${entityName}`;
    if (isReport) filteredURL = `${filteredURL}&IsReport=${isReport}`;

    try {
      const result = await httpRequest({
        url: filteredURL,
        urlType: 'settings',
      });
      if (result.code === 1) {
        if (isReport) {
          handleConvertByte(result?.data?.reportData, result?.data?.reportName);
          dispatch({ type: SettingsTypes.LISTING_RESET });
        } else {
          dispatch({ type: SettingsTypes.DATABASE_LOG, payload: result?.data });
        }
      }

      if (result.code !== 1) {
        dispatch({ type: SettingsTypes.LISTING_RESET });
        if (result.shortDescription) enqueueSnackbar(result.shortDescription, { variant: 'error' });
      }
    } catch (error) {
      dispatch({ type: SettingsTypes.LISTING_RESET });
      enqueueSnackbar(error?.message || 'Unable to fetch data! Please refresh and try again', { variant: 'error' });
    }
  };

export const getUserDataBaseLogByEmailAction =
  (enqueueSnackbar, email, pageNumber = 1, pageSize = 50, paginated = false) =>
  async (dispatch) => {
    dispatch({ type: SettingsTypes.FETCHING_SETTINGS });
    let filteredURL = `DatabaseLog/Databaselog?Email=${email}&Descending=true`;
    if (paginated) filteredURL = `${filteredURL}&PageNumber=${pageNumber}&PageSize=${pageSize}&Paginated=${paginated}`;

    try {
      const result = await httpRequest({
        // url: `DatabaseLog/Databaselog?Email=${email}`,
        url: filteredURL,
        urlType: 'settings',
      });

      if (result.code === 1) {
        dispatch({ type: SettingsTypes.USER_DATABASE_LOG, payload: result?.data });
      }

      if (result.code !== 1) {
        dispatch({ type: SettingsTypes.LISTING_RESET });
        if (result.shortDescription) enqueueSnackbar(result.shortDescription, { variant: 'error' });
      }
    } catch (error) {
      dispatch({ type: SettingsTypes.LISTING_RESET });
      enqueueSnackbar(error?.message || 'Unable to fetch data! Please refresh and try again', { variant: 'error' });
    }
  };
// export const getUserDataBaseLogByClassNameAction = (enqueueSnackbar, users) => async (dispatch) => {
//   dispatch({ type: SettingsTypes.LOADING_SETTINGS });
//   try {
//     const result = await httpRequest({
//       url: `DatabaseLog/Databaselog?EntityName=${users}`,
//       // url: `DatabaseLog/Databaselog?Email=${email}`,
//       urlType: 'settings',
//     });

//     if (result.code === 1) {
//       dispatch({ type: SettingsTypes.USER_DATABASE_LOG, payload: result?.data });
//     }

//     if (result.code !== 1) {
//       dispatch({ type: SettingsTypes.LISTING_RESET });
//       if (result.shortDescription) enqueueSnackbar(result.shortDescription, { variant: 'error' });
//     }
//   } catch (error) {
//     dispatch({ type: SettingsTypes.LISTING_RESET });
//     enqueueSnackbar(error?.message || 'Unable to fetch data! Please refresh and try again', { variant: 'error' });
//   }
// };
export const employeeRoutesAction =
  (enqueueSnackbar, pageNumber = 1, pageSize = 50, paginated = true) =>
  async (dispatch) => {
    dispatch({ type: SettingsTypes.LOADING_SETTINGS });
    let filteredURL = `Route/employeeroutes?Descending=true`;
    if (paginated) filteredURL = `${filteredURL}&PageNumber=${pageNumber}&PageSize=${pageSize}&Paginated=${paginated}`;

    try {
      const result = await httpRequest({
        url: filteredURL,
        urlType: 'settings',
      });
      if (result.code === 1) {
        dispatch({ type: SettingsTypes.EMPLOYEE_ROUTE, payload: result?.data });
      }

      if (result.code !== 1) {
        dispatch({ type: SettingsTypes.LISTING_RESET });
        if (result.shortDescription) enqueueSnackbar(result.shortDescription, { variant: 'error' });
      }
    } catch (error) {
      dispatch({ type: SettingsTypes.LISTING_RESET });
      enqueueSnackbar(error?.message || 'Unable to fetch data! Please refresh and try again', { variant: 'error' });
    }
  };
export const getRouteByTerminalIDAction = (enqueueSnackbar, terminalID) => async (dispatch) => {
  dispatch({ type: SettingsTypes.LOADING_SETTINGS });
  try {
    const result = await httpRequest({
      url: `Terminal/RouteList/${terminalID}`,
      urlType: 'settings',
    });

    if (result.code === 1) {
      dispatch({ type: SettingsTypes.GET_ROUTE_BY_TERMINAL_ID, payload: result?.data });
    }

    if (result.code !== 1) {
      dispatch({ type: SettingsTypes.LISTING_RESET });
      if (result.shortDescription) enqueueSnackbar(result.shortDescription, { variant: 'error' });
    }
  } catch (error) {
    dispatch({ type: SettingsTypes.LISTING_RESET });
    enqueueSnackbar(error?.message || 'Unable to fetch data! Please refresh and try again', { variant: 'error' });
  }
};

export const getTravelDocumentAction =
  (enqueueSnackbar, pageNumber = 1, pageSize = 50, paginated = false) =>
  async (dispatch) => {
    dispatch({ type: SettingsTypes.LOADING_SETTINGS });
    let filteredURL = `TravelDocument?Descending=true`;
    if (paginated) filteredURL = `${filteredURL}&PageNumber=${pageNumber}&PageSize=${pageSize}&Paginated=${paginated}`;

    try {
      const result = await httpRequest({
        url: filteredURL,
        urlType: 'settings',
      });

      if (result.code === 1) {
        dispatch({ type: SettingsTypes.DOCUMENT_TYPE, payload: result?.data });
      }

      if (result.code !== 1) {
        dispatch({ type: SettingsTypes.LISTING_RESET });
        if (result.shortDescription) enqueueSnackbar(result.shortDescription, { variant: 'error' });
      }
    } catch (error) {
      dispatch({ type: SettingsTypes.LISTING_RESET });
      enqueueSnackbar(error?.message || 'Unable to fetch data! Please refresh and try again', { variant: 'error' });
    }
  };
export const getTravelDocumentFeeAction =
  (enqueueSnackbar, travelDocumentId = '', countryId = '', pageNumber = 1, pageSize = 50, paginated = false) =>
  async (dispatch) => {
    dispatch({ type: SettingsTypes.LOADING_SETTINGS });

    let filteredURL = `TravelDocumentFee?Descending=true`;
    if (countryId) filteredURL = `${filteredURL}&CountryId=${countryId}`;
    if (travelDocumentId) filteredURL = `${filteredURL}&TravelDocumentId=${travelDocumentId}`;
    if (paginated) filteredURL = `${filteredURL}&PageNumber=${pageNumber}&PageSize=${pageSize}&Paginated=${paginated}`;

    try {
      const result = await httpRequest({
        url: filteredURL,
        urlType: 'settings',
      });
      if (result.code === 1) {
        dispatch({ type: SettingsTypes.DOCUMENT_TYPE_FEE, payload: result?.data });
      }

      if (result.code !== 1) {
        dispatch({ type: SettingsTypes.LISTING_RESET });
        if (result.shortDescription) enqueueSnackbar(result.shortDescription, { variant: 'error' });
      }
    } catch (error) {
      dispatch({ type: SettingsTypes.LISTING_RESET });
      enqueueSnackbar(error?.message || 'Unable to fetch data! Please refresh and try again', { variant: 'error' });
    }
  };

export const vehiclesInTerminalAction = (enqueueSnackbar, terminaId, VmodelId) => async (dispatch) => {
  dispatch({ type: SettingsTypes.LOADING_SETTINGS });
  try {
    const result = await httpRequest({
      url: `Vehicle/GetAvailableVehiclesInTerminalWithAssigned/${terminaId}${VmodelId ? `?VmodelId=${VmodelId}` : ''}`,
      urlType: 'settings',
    });

    if (result.code === 1) {
      dispatch({ type: SettingsTypes.VEHICLE_IN_TERMINAL_LIST, payload: result?.data });
    }

    if (result.code !== 1) {
      dispatch({ type: SettingsTypes.LISTING_RESET });
      if (result.shortDescription) enqueueSnackbar(result.shortDescription, { variant: 'error' });
    }
  } catch (error) {
    dispatch({ type: SettingsTypes.LISTING_RESET });
    enqueueSnackbar(error?.message || 'Unable to fetch data! Please refresh and try again', { variant: 'error' });
  }
};

// export const getPartnerTypeAction = (enqueueSnackbar) => async (dispatch) => {
//   dispatch({ type: SettingsTypes.LOADING_SETTINGS });
//   try {
//     const result = await httpRequest({
//       url: `Partner/dispatchpatnertypes`,
//       urlType: 'settings',
//     });

//     if (result.code === 1) {
//       dispatch({ type: SettingsTypes.GET_PARTNER_TYPE, payload: result?.data });
//     }

//     if (result.code !== 1) {
//       dispatch({ type: SettingsTypes.LISTING_RESET });
//       if (result.shortDescription) enqueueSnackbar(result.shortDescription, { variant: 'error' });
//     }
//   } catch (error) {
//     dispatch({ type: SettingsTypes.LISTING_RESET });
//     enqueueSnackbar(error?.message || 'Unable to fetch data! Please refresh and try again', { variant: 'error' });
//   }
// };

export const getSubRouteByRouteIdAction =
  (enqueueSnackbar, routeId = '', vehicleModelId = '') =>
  async (dispatch) => {
    dispatch({ type: SettingsTypes.LOADING_SETTINGS });
    try {
      const result = await httpRequest({
        url: `SubRoute/SubRouteByRouteId?Id=${routeId}&VehiclemodelId=${vehicleModelId}`,
        urlType: 'settings',
      });

      if (result.code === 1) {
        dispatch({ type: SettingsTypes.SUB_ROUTE_LIST_BY_ROUTE_ID, payload: result?.data });
      }

      if (result.code !== 1) {
        dispatch({ type: SettingsTypes.LISTING_RESET });
        if (result.shortDescription) enqueueSnackbar(result.shortDescription, { variant: 'error' });
      }
    } catch (error) {
      dispatch({ type: SettingsTypes.LISTING_RESET });
      enqueueSnackbar(error?.message || 'Unable to fetch data! Please refresh and try again', { variant: 'error' });
    }
  };
export const getSubRouteAction =
  (enqueueSnackbar, parameter = '', value = '', paginated = false, pageNumber = 1, pageSize = 50) =>
  async (dispatch) => {
    let filteredURL = `SubRoute?Paginated=${paginated}&Descending=true`;
    if (paginated) filteredURL = `${filteredURL}&pageNumber=${pageNumber}&pageSize=${pageSize}`;
    if (parameter) filteredURL = `${filteredURL}&Search=${value}&Parameter=${parameter}`;

    dispatch({ type: SettingsTypes.LOADING_SETTINGS });
    try {
      const result = await httpRequest({
        url: filteredURL,
        urlType: 'settings',
      });

      if (result.code === 1) {
        dispatch({ type: SettingsTypes.GET_SUB_ROUTE_LIST, payload: result?.data });
      }

      if (result.code !== 1) {
        dispatch({ type: SettingsTypes.LISTING_RESET });
        if (result.shortDescription) enqueueSnackbar(result.shortDescription, { variant: 'error' });
      }
    } catch (error) {
      dispatch({ type: SettingsTypes.LISTING_RESET });
      enqueueSnackbar(error?.message || 'Unable to fetch data! Please refresh and try again', { variant: 'error' });
    }
  };

export const getAvailableVehiclesAction = (enqueueSnackbar) => async (dispatch) => {
  dispatch({ type: SettingsTypes.LOADING_SETTINGS });
  try {
    const result = await httpRequest({
      url: `Vehicle/AvailableVehicles`,
      urlType: 'settings',
    });

    if (result.code === 1) {
      dispatch({ type: SettingsTypes.GET_AVAILABLE_VEHICLES, payload: result?.data });
    }

    if (result.code !== 1) {
      dispatch({ type: SettingsTypes.LISTING_RESET });
      if (result.shortDescription) enqueueSnackbar(result.shortDescription, { variant: 'error' });
    }
  } catch (error) {
    dispatch({ type: SettingsTypes.LISTING_RESET });
    enqueueSnackbar(error?.message || 'Unable to fetch data! Please refresh and try again', { variant: 'error' });
  }
};

export const GetVirtualBusesByRouteIdAction = (enqueueSnackbar, routeId) => async (dispatch) => {
  dispatch({ type: SettingsTypes.LOADING_SETTINGS });
  try {
    const result = await httpRequest({
      url: `Route/GetVirtualBusesByRouteId/${routeId}`,
      urlType: 'settings',
    });

    if (result.code === 1) {
      dispatch({ type: SettingsTypes.GET_VIRTUAL_BUS_BY_ROUTE, payload: result?.data });
    }

    if (result.code !== 1) {
      dispatch({ type: SettingsTypes.LISTING_RESET });
      if (result.shortDescription) enqueueSnackbar(result.shortDescription, { variant: 'error' });
    }
  } catch (error) {
    dispatch({ type: SettingsTypes.LISTING_RESET });
    // enqueueSnackbar(error?.message || 'Unable to fetch data! Please refresh and try again', { variant: 'error' });
  }
};
export const getPaymentGetwayAction =
  (enqueueSnackbar, pageNumber = 1, pageSize = 50, paginated = false) =>
  async (dispatch) => {
    dispatch({ type: SettingsTypes.LOADING_SETTINGS });
    let filteredURL = `PaymentGateway?Descending=true`;
    if (paginated) filteredURL = `${filteredURL}&PageNumber=${pageNumber}&PageSize=${pageSize}&Paginated=${paginated}`;

    try {
      const result = await httpRequest({
        url: filteredURL,
        urlType: 'settings',
      });

      if (result.code === 1) {
        dispatch({ type: SettingsTypes.PAYMENT_GATE_LIST, payload: result?.data });
      }

      if (result.code !== 1) {
        dispatch({ type: SettingsTypes.LISTING_RESET });
        if (result.shortDescription) enqueueSnackbar(result.shortDescription, { variant: 'error' });
      }
    } catch (error) {
      dispatch({ type: SettingsTypes.LISTING_RESET });
      enqueueSnackbar(error?.message || 'Unable to fetch data! Please refresh and try again', { variant: 'error' });
    }
  };

export const toggleAvailableOnline = (enqueueSnackbar, terminalId) => async (dispatch) => {
  try {
    const result = await httpRequest({
      url: `Terminal/toggleonline/${terminalId}`,
      urlType: 'settings',
      method: 'PUT',
    });

    if (result.code === 1) {
      const { terminalList } = store.getState().settings;
      const updatedList = terminalList.userData.map((list) => {
        if (list.id === terminalId) {
          list.availableOnline = !list.availableOnline;
        }
        return list;
      });

      terminalList.userData = updatedList;

      dispatch({ type: SettingsTypes.GET_TERMINAL_LIST, payload: terminalList });
      enqueueSnackbar('Status Updated Succesfully!', { variant: 'success' });
    }

    if (result.code !== 1) {
      if (result.shortDescription) enqueueSnackbar(result.shortDescription, { variant: 'error' });
    }
  } catch (error) {
    enqueueSnackbar(error?.message || 'Unable to fetch data! Please refresh and try again', { variant: 'error' });
  }
};

export const filterVehicleAction =
  (
    enqueueSnackbar,
    vehicleRegNumber = '',
    vehicleModelId = '',
    partnerId = '',
    ebm = '',
    // eslint-disable-next-line no-unused-vars
    vehicleStatus = '',
    paginated = false
  ) =>
  async (dispatch) => {
    dispatch({ type: SettingsTypes.FETCHING_SETTINGS });
    try {
      let filteredUrl = `Vehicle?Descending=true&Paginated=${paginated}`;

      if (vehicleRegNumber) {
        filteredUrl = `${filteredUrl}&VehicleRegistrationNumber=${vehicleRegNumber}`;
      }
      if (vehicleModelId) {
        filteredUrl = `${filteredUrl}&VehicleModelId=${vehicleModelId}`;
      }
      if (partnerId) {
        filteredUrl = `${filteredUrl}&PartnerId=${partnerId}`;
      }
      if (ebm) {
        filteredUrl = `${filteredUrl}&EBM=${ebm}`;
      }
      // if (vehicleStatus) {
      //   filteredUrl = `${filteredUrl}&VehicleStatus=${vehicleStatus}`;
      // }

      const result = await httpRequest({
        url: filteredUrl,
        urlType: 'settings',
      });
      if (result.code === 1) {
        dispatch({ type: SettingsTypes.GET_VEHICLE_LIST, payload: result?.data });
      }

      if (result.code !== 1) {
        dispatch({ type: SettingsTypes.LISTING_RESET });
        if (result.shortDescription) enqueueSnackbar(result.shortDescription, { variant: 'error' });
      }
    } catch (error) {
      dispatch({ type: SettingsTypes.LISTING_RESET });
      enqueueSnackbar(error?.message || 'Unable to fetch data! Please refresh and try again', { variant: 'error' });
    }
  };

export const getExendedDiscount =
  (enqueueSnackbar, pageNumber = 1, pageSize = 50, paginated = false) =>
  async (dispatch) => {
    dispatch({ type: SettingsTypes.LOADING_SETTINGS });
    let filteredURL = `ExtendedDiscount?Descending=true`;
    if (paginated) filteredURL = `${filteredURL}&PageNumber=${pageNumber}&PageSize=${pageSize}&Paginated=${paginated}`;

    try {
      const result = await httpRequest({
        url: filteredURL,
        urlType: 'settings',
      });

      if (result.code === 1) {
        dispatch({ type: SettingsTypes.EXTENDED_DISCOUNT_LIST, payload: result?.data });
      }

      if (result.code !== 1) {
        if (result.shortDescription) enqueueSnackbar(result.shortDescription, { variant: 'error' });
        dispatch({ type: SettingsTypes.LISTING_RESET });
      }
    } catch (error) {
      dispatch({ type: SettingsTypes.LISTING_RESET });
      enqueueSnackbar(error?.message || 'Unable to fetch data! Please refresh and try again', { variant: 'error' });
    }
  };

export const getDepartureByTerminals = (enqueueSnackbar, destinationTerminalId) => async (dispatch) => {
  dispatch({ type: SettingsTypes.LOADING_SETTINGS });
  try {
    const result = await httpRequest({
      url: `Terminal/GetDepartureTerminals/${destinationTerminalId}`,
      urlType: 'settings',
    });
    if (result.code === 1) {
      dispatch({ type: SettingsTypes.TERMINAL_BY_DEPARTURE_SUCCESS, payload: result?.data });
    }

    if (result.code !== 1) {
      if (result.shortDescription) enqueueSnackbar(result.shortDescription, { variant: 'error' });
      dispatch({ type: SettingsTypes.LISTING_RESET });
    }
  } catch (error) {
    dispatch({ type: SettingsTypes.LISTING_RESET });
    enqueueSnackbar(error?.message || 'Unable to fetch data! Please refresh and try again', { variant: 'error' });
  }
};

export const regionByCountryAction = (enqueueSnackbar, countryId) => async (dispatch) => {
  dispatch({ type: SettingsTypes.LOADING_SETTINGS });
  try {
    const result = await httpRequest({
      url: `Region/RegionByCountryId/${countryId}`,
      urlType: 'settings',
    });

    if (result.code === 1) {
      dispatch({ type: SettingsTypes.FIND_REGION_BY_COUNTRY, payload: result?.data });
    }

    if (result.code !== 1) {
      // dispatch({ type: SettingsTypes.LISTING_RESET });
      if (result.shortDescription) enqueueSnackbar(result.shortDescription, { variant: 'error' });
    }
  } catch (error) {
    dispatch({ type: SettingsTypes.LISTING_RESET });
    enqueueSnackbar(error?.message || 'Unable to fetch data! Please refresh and try again', { variant: 'error' });
  }
};

export const stateByRegionAction = (enqueueSnackbar, regionId) => async (dispatch) => {
  dispatch({ type: SettingsTypes.LOADING_SETTINGS });
  try {
    const result = await httpRequest({
      url: `State/StateByRegionId/${regionId}`,
      urlType: 'settings',
    });

    if (result.code === 1) {
      dispatch({ type: SettingsTypes.FIND_STATE_BY_REGION, payload: result?.data });
    }

    if (result.code !== 1) {
      dispatch({ type: SettingsTypes.LISTING_RESET });
      if (result.shortDescription) enqueueSnackbar(result.shortDescription, { variant: 'error' });
    }
  } catch (error) {
    dispatch({ type: SettingsTypes.LISTING_RESET });
    enqueueSnackbar(error?.message || 'Unable to fetch data! Please refresh and try again', { variant: 'error' });
  }
};

export const terminalByStateAction = (enqueueSnackbar, stateId) => async (dispatch) => {
  dispatch({ type: SettingsTypes.LOADING_SETTINGS });
  try {
    const result = await httpRequest({
      url: `Terminal/TerminalByStateId/${stateId}`,
      urlType: 'settings',
    });

    if (result.code === 1) {
      dispatch({ type: SettingsTypes.FIND_TERMINAL_BY_STATE, payload: result?.data });
    }

    if (result.code !== 1) {
      dispatch({ type: SettingsTypes.LISTING_RESET });
      if (result.shortDescription) enqueueSnackbar(result.shortDescription, { variant: 'error' });
    }
  } catch (error) {
    dispatch({ type: SettingsTypes.LISTING_RESET });
    enqueueSnackbar(error?.message || 'Unable to fetch data! Please refresh and try again', { variant: 'error' });
  }
};

export const routeByTerminalAction = (enqueueSnackbar, terminalId) => async (dispatch) => {
  dispatch({ type: SettingsTypes.LOADING_SETTINGS });
  try {
    const result = await httpRequest({
      url: `Terminal/RouteList/${terminalId}`,
      urlType: 'settings',
    });

    if (result.code === 1) {
      dispatch({ type: SettingsTypes.FIND_ROUTE_BY_TERMINAL, payload: result?.data });
    }

    if (result.code !== 1) {
      dispatch({ type: SettingsTypes.LISTING_RESET });
      if (result.shortDescription) enqueueSnackbar(result.shortDescription, { variant: 'error' });
    }
  } catch (error) {
    dispatch({ type: SettingsTypes.LISTING_RESET });
    enqueueSnackbar(error?.message || 'Unable to fetch data! Please refresh and try again', { variant: 'error' });
  }
};

export const tripByRouteAction = (enqueueSnackbar, routeId) => async (dispatch) => {
  dispatch({ type: SettingsTypes.LOADING_SETTINGS });
  try {
    const result = await httpRequest({
      url: `Trips?RouteId=${routeId}&AvailableOnline=true&Paginated=false`,
      // url: `Trips?RouteId=${routeId}`,
      urlType: 'bookings',
    });

    if (result.code === 1) {
      dispatch({ type: SettingsTypes.FIND_TRIPS_BY_ROUTE, payload: result?.data });
    }

    if (result.code !== 1) {
      if (result.shortDescription) enqueueSnackbar(result.shortDescription, { variant: 'error' });
    }
  } catch (error) {
    dispatch({ type: SettingsTypes.LISTING_RESET });
    enqueueSnackbar(error?.message || 'Unable to fetch data! Please refresh and try again', { variant: 'error' });
  }
};

export const couponAction =
  (enqueueSnackbar, details, pageNumber, pageSize, paginated = false) =>
  async (dispatch = {}) => {
    dispatch({ type: SettingsTypes.LOADING_SETTINGS });
    try {
      let filteredURL = `Coupon?Descending=true&Paginated=${paginated}&PageNumber=${pageNumber}&PageSize=${pageSize}`;
      if (details?.startDate) filteredURL = `${filteredURL}&startDate=${details.startDate}`;
      if (details?.endDate) filteredURL = `${filteredURL}&endDate=${details.endDate}`;
      if (details?.couponCode) filteredURL = `${filteredURL}&couponCode=${details?.couponCode}`;
      if (details?.couponValue) filteredURL = `${filteredURL}&couponValue=${details?.couponValue}`;
      if (details?.couponType) filteredURL = `${filteredURL}&couponType=${details?.couponType}`;
      if (details?.routeId) filteredURL = `${filteredURL}&RouteId=${details?.routeId}`;
      if (details?.expirationDate) filteredURL = `${filteredURL}&ExpiryDate=${details?.expirationDate}`;

      const result = await httpRequest({
        url: filteredURL,
        urlType: 'settings',
      });

      if (result.code === 1) {
        dispatch({ type: SettingsTypes.COUPON_LIST, payload: result?.data });
      }

      if (result.code !== 1) {
        dispatch({ type: SettingsTypes.LISTING_RESET });
        if (result.shortDescription) enqueueSnackbar(result.shortDescription, { variant: 'error' });
      }
    } catch (error) {
      dispatch({ type: SettingsTypes.LISTING_RESET });
      enqueueSnackbar(error?.message || 'Unable to fetch data! Please refresh and try again', { variant: 'error' });
    }
  };
