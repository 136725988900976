import * as BookingTypes from './bookingsTypes';

const initalState = {
  loading: false,
  inComingCount: 0,
  error: '',
  stateList: [],
  fixedChargesList: {},
  fuelCostList: {},
  regionList: {},
  countryList: [],
  discountList: [],
  routeList: {},
  routePaginatedList: {},
  outgoingJourney: {},
  incomingJourney: {},
  newRouteFare: { isSub: false, fare: 0 },
  dailyJourney: { userData: [] },
  terminalList: {},
  manageBusInformation: {},
  combinedBusSearch: {},
  terminalByDestinationList: [],
  onlineRouteList: [],
  availableTripsList: {},
  fetchingBooking: false,
  rescheduleRerouteList: { currentPage: 0, userData: [] },
  bookingList: { currentPage: 0, userData: [] },
  userBookingDetails: {},
  userBookinginfomation: {},
  ticketSalesRecordList: {},
  moreTickets: [],
  loadingMore: false,
  availableSeatInfo: { remainingSeats: [], excludedSeats: [], bookedSeats: [], totalNumberOfSeatsForTheBus: 0 },
  bookingCharges: 0.0,
  pickUpPointList: {},
  pickUpPointReport: {},
  sharedRevenueList: {},
  tripList: {},
  bookedVehiclesList: [],
  manifestDetails: {},
  tripsWithPhysicalBusList: {},
  todayTripsWithPhysicalBusList: {},
  fareList: {},
  fareAmountDetails: {},
  manifestPrintDetails: {},
  hireBookingList: {},
  hiredVehicleList: {},
  pendingHireRequest: {},
  pendingHireDetails: {},
  ebmList: [],
  salesDetailsInfo: {
    online: 0,
    bookedOnHold: 0,
    terminalPOS: 0,
    terminalCash: 0,
    advancedPOS: 0,
    advancedCash: 0,
    rescheduleFee: 0,
    rerouteFee: 0,
  },
  hireServiceReportList: { userData: [] },
  fareCalenderList: {},
  fleetHistoryList: { userData: [] },
  blownBusReport: { userData: [] },
  departureReport: { userData: [] },
  fleetMovementList: [],
  extraDispatchList: { userData: [] },
  blownBusList: { userData: [] },
  fleetUsageReportList: [],
  partnerVehicleReport: [],
  partnerVehicleRepairReport: [],
  opsFleetSummary: [],
  opsFleetTerminalSummary: [],
  fleetFinancialList: { userData: [] },
  allFleetFinancialList: { userData: [] },
  captainRatingList: { userData: [] },
  captainRatingDetails: [],
  captainRequestList: { userData: [] },
  captainSwapList: { userData: [] },
  userBookingHistoryList: { userData: [] },
  userBoatHistoryList: { userData: [] },
  userBusHistoryList: { userData: [] },
  userFlightHistoryList: {},
  userWalletOutFlow: {},
  userWalletInFlow: { userData: [] },
  userAirtimeHistory: { userData: [] },
  dispatchFeeList: {},
  terminalBookingReport: {},
  advanceBookingReport: {},
  advanceBookingReportDetail: {},
  terminalBookingReportDetail: {},
  shortageReport: {},
  allStartUpReportList: { userData: [] },
  businessPartnerPaymentList: {},
  businessPartnerBookingList: { userData: [] },
  bookChannelReportList: {},
};

// eslint-disable-next-line
const bookingsReducer = (state = initalState, action) => {
  switch (action.type) {
    case BookingTypes.FETCHING_BOOKING:
      return {
        ...state,
        fetchingBooking: true,
        error: '',
      };
    case BookingTypes.LOADING_BOOKING:
      return {
        ...state,
        loading: true,
        error: '',
      };
    case BookingTypes.LOADING_INCOMPLETE:
      return {
        ...state,
        inComingCount: 0,
        error: '',
      };

    case BookingTypes.SHORTAGE_REPORT:
      return {
        ...state,
        loading: false,
        error: '',
        shortageReport: action.payload,
      };
    case BookingTypes.TERMINAL_BOOKING_REPORT_DETAILS:
      return {
        ...state,
        loading: false,
        error: '',
        terminalBookingReportDetail: action.payload,
      };
    case BookingTypes.ADVANCE_BOOKING_REPORT_DETAILS:
      return {
        ...state,
        loading: false,
        error: '',
        advanceBookingReportDetail: action.payload,
      };
    case BookingTypes.ADVANCE_BOOKING_REPORT:
      return {
        ...state,
        loading: false,
        error: '',
        advanceBookingReport: action.payload,
      };
    case BookingTypes.TERMINAL_BOOKING_REPORT:
      return {
        ...state,
        loading: false,
        error: '',
        terminalBookingReport: action.payload,
      };
    case BookingTypes.DISPATCH_FEE_LIST:
      return {
        ...state,
        loading: false,
        error: '',
        dispatchFeeList: action.payload,
      };
    case BookingTypes.USER_AIRTME_PAYMENT_HISTORY:
      return {
        ...state,
        loading: false,
        error: '',
        userAirtimeHistory: action.payload,
      };

    case BookingTypes.USER_WALLET_INFLOW_HISTORY:
      return {
        ...state,
        loading: false,
        error: '',
        userWalletInFlow: action.payload,
      };
    case BookingTypes.USER_WALLET_OUTFLOW_HISTORY:
      return {
        ...state,
        loading: false,
        error: '',
        userWalletOutFlow: action.payload,
      };
    case BookingTypes.USER_BOAT_HISTORY:
      return {
        ...state,
        userBoatHistoryList: action.payload,
      };
    case BookingTypes.USER_BOOKING_HISTORY:
      return {
        ...state,
        loading: false,
        error: '',
        userBookingHistoryList: action.payload,
      };
    case BookingTypes.USER_BUS_HISTORY:
      return {
        ...state,
        loading: false,
        error: '',
        userBusHistoryList: action.payload,
      };

    case BookingTypes.SET_AVAILABLE_SEATS:
      return {
        ...state,
        loading: false,
        availableSeatInfo: action.payload,
      };
    case BookingTypes.SET_BOOKING_CHARGES:
      return {
        ...state,
        bookingCharges: action.payload,
        isCompleted: true,
        loading: false,
      };
    case BookingTypes.CLEAR_BOOKING_AMOUNT:
      return {
        ...state,
        loadingMore: false,
        isCompleted: false,
        bookingCharges: 0,
        error: '',
      };
    case BookingTypes.MANIFEST_PRINT:
      return {
        ...state,
        loading: false,
        manifestPrintDetails: action.payload,
      };
    case BookingTypes.TRIP_LISTING_SUCCESS:
      return {
        ...state,
        loading: false,
        tripList: action.payload,
      };
    case BookingTypes.SHARED_JOURNEY_SUCCESS:
      return {
        ...state,
        loading: false,
        sharedRevenueList: action.payload,
      };
    case BookingTypes.SEARCH_HIRE_BOOKINGS:
      return {
        ...state,
        fetchingBooking: false,
        hireBookingList: action.payload,
      };
    case BookingTypes.GET_HIRED_VEHICLES:
      return {
        ...state,
        fetchingBooking: false,
        hiredVehicleList: action.payload,
      };
    case BookingTypes.GET_PENDING_HIRE_REQUEST:
      return {
        ...state,
        fetchingBooking: false,
        pendingHireRequest: action.payload,
      };
    case BookingTypes.GET_PENDING_HIRE_DETAILS:
      return {
        ...state,
        fetchingBooking: false,
        pendingHireDetails: action.payload,
      };
    case BookingTypes.SALES_DETAILS_SUCCESS:
      return {
        ...state,
        salesDetailsInfo: action.payload,
      };
    case BookingTypes.FIXED_CHARGES_SETTINGS:
      return {
        ...state,
        loading: false,
        error: '',
        fixedChargesList: action.payload,
      };
    case BookingTypes.FUEL_COST_SETTINGS:
      return {
        ...state,
        loading: false,
        error: '',
        fuelCostList: action.payload,
      };
    case BookingTypes.STATE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: '',
        stateList: action.payload,
      };
    case BookingTypes.REGION_SUCCESS:
      return {
        ...state,
        loading: false,
        error: '',
        regionList: action.payload,
      };
    case BookingTypes.COUNTRY_SUCCESS:
      return {
        ...state,
        loading: false,
        error: '',
        countryList: action.payload,
      };
    case BookingTypes.ROUTE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: '',
        routeList: action.payload,
      };
    case BookingTypes.ROUTE_SUCCESS_PAGINATED:
      return {
        ...state,
        loading: false,
        error: '',
        routePaginatedList: action.payload,
      };
    case BookingTypes.EXTRA_DISPATCH:
      return {
        ...state,
        loading: false,
        error: '',
        extraDispatchList: action.payload,
      };
    case BookingTypes.OUTGOING_JOURNEY:
      return {
        ...state,
        loading: false,
        error: '',
        outgoingJourney: action.payload,
      };
    case BookingTypes.INCOMING_JOURNEY:
      return {
        ...state,
        loading: false,
        error: '',
        incomingJourney: action.payload,
      };
    case BookingTypes.SET_NEW_ROUTE_FARE:
      return {
        ...state,
        loading: false,
        error: '',
        newRouteFare: action.payload,
      };
    case BookingTypes.INCOMING_JOURNEY_COUNT:
      return {
        ...state,
        error: '',
        inComingCount: action?.payload?.userData?.length || 0,
      };
    case BookingTypes.DAILY_JOURNEY:
      return {
        ...state,
        loading: false,
        error: '',
        dailyJourney: action.payload,
      };
    case BookingTypes.MANAGE_BUS_INFO:
      return {
        ...state,
        loading: false,
        error: '',
        manageBusInformation: action.payload,
      };
    case BookingTypes.PICKUP_POINT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: '',
        pickUpPointList: action.payload,
      };
    case BookingTypes.PICKUP_REPORT:
      return {
        ...state,
        loading: false,
        error: '',
        pickUpPointReport: action.payload,
      };
    case BookingTypes.FLEET_USAGE_REPORT:
      return {
        ...state,
        loading: false,
        error: '',
        fleetUsageReportList: action.payload,
      };
    case BookingTypes.OPS_FLEET_TERMINAL_SUMMARY:
      return {
        ...state,
        loading: false,
        error: '',
        opsFleetTerminalSummary: action.payload,
      };
    case BookingTypes.OPS_FLEET_SUMMARY:
      return {
        ...state,
        loading: false,
        error: '',
        opsFleetSummary: action.payload,
      };
    case BookingTypes.PARTNER_FLEET_REPAIR_REPORT:
      return {
        ...state,
        loading: false,
        error: '',
        partnerVehicleRepairReport: action.payload,
      };
    case BookingTypes.PARTNER_FLEET_REPORT:
      return {
        ...state,
        loading: false,
        error: '',
        partnerVehicleReport: action.payload,
      };
    case BookingTypes.ALL_FLEET_FINANCIAL_DETAIL:
      return {
        ...state,
        loading: false,
        error: '',
        allFleetFinancialList: action.payload,
      };
    case BookingTypes.CAPTAIN_RATING_LIST:
      return {
        ...state,
        loading: false,
        error: '',
        captainRatingList: action.payload,
      };
    case BookingTypes.CAPTAIN_RATING_DETAILS:
      return {
        ...state,
        fetchingBooking: false,
        error: '',
        captainRatingDetails: action.payload,
      };
    case BookingTypes.PARTNER_FLEET_FINANCIAL:
      return {
        ...state,
        loading: false,
        error: '',
        fleetFinancialList: action.payload,
      };
    case BookingTypes.BLOWN_BUS:
      return {
        ...state,
        loading: false,
        error: '',
        blownBusList: action.payload,
      };
    case BookingTypes.CAPTAIN_APPROVAL_REQUEST:
      return {
        ...state,
        loading: false,
        error: '',
        captainRequestList: action.payload,
      };
    case BookingTypes.CAPTAIN_SWAP_REQUEST:
      return {
        ...state,
        loading: false,
        error: '',
        captainSwapList: action.payload,
      };
    case BookingTypes.GET_BUSINESS_PARTNER:
      return {
        ...state,
        loading: false,
        error: '',
        businessPartnerList: action.payload,
      };
    case BookingTypes.GET_BUSINESS_PARTNER_PAYMENT:
      return {
        ...state,
        loading: false,
        error: '',
        businessPartnerPaymentList: action.payload,
      };
    case BookingTypes.GET_BUSINESS_PARTNER_BOOKING:
      return {
        ...state,
        loading: false,
        error: '',
        businessPartnerBookingList: action.payload,
      };
    case BookingTypes.GET_BOOKING_CHANNEL_REPORT:
      return {
        ...state,
        loading: false,
        fetchingBooking: false,
        error: '',
        bookChannelReportList: action.payload,
      };
    case BookingTypes.BOOKING_ANALYSIS:
      return {
        ...state,
        loading: false,
        error: '',
        bookingAnalysis: action.payload,
      };

    case BookingTypes.ONLINE_ROUTE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: '',
        onlineRouteList: action.payload,
      };
    case BookingTypes.TICKET_SALES_RECORDS:
      return {
        ...state,
        loading: false,
        error: '',
        ticketSalesRecordList: action.payload,
      };
    case BookingTypes.COMBINED_BUS_SEARCH:
      return {
        ...state,
        loading: false,
        error: '',
        combinedBusSearch: action.payload,
      };
    case BookingTypes.SET_AVAILABLE_TRIPS:
      return {
        ...state,
        fetchingBooking: false,
        error: '',
        availableTripsList: action.payload,
      };
    case BookingTypes.USER_BOOKING_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: '',
        userBookingDetails: action.payload,
      };
    case BookingTypes.USER_BOOKING_INFO_SUCCESS:
      return {
        ...state,
        fetchingBooking: false,
        error: '',
        userBookinginfomation: action.payload,
      };
    case BookingTypes.BOOKING_SUCCESS:
      return {
        ...state,
        fetchingBooking: false,
        error: '',
        bookingList: action.payload,
      };
    case BookingTypes.GET_RESCHEDULE_REROUTE_REPORT:
      return {
        ...state,
        fetchingBooking: false,
        error: '',
        rescheduleRerouteList: action.payload,
      };
    case BookingTypes.MORE_TICKET_SUCCESS:
      return {
        ...state,
        loadingMore: false,
        error: '',
        moreTickets: action.payload,
      };
    case BookingTypes.LOADING_MORE:
      return {
        ...state,
        loadingMore: true,
        isCompleted: false,
        moreTickets: [],
        error: '',
      };

    case BookingTypes.TERMINAL_SUCCESS:
      return {
        ...state,
        loading: false,
        error: '',
        terminalList: action.payload,
      };
    case BookingTypes.TERMINAL_BY_DESTINATION_SUCCESS:
      return {
        ...state,
        loading: false,
        error: '',
        terminalByDestinationList: action.payload,
      };
    case BookingTypes.DISCOUNT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: '',
        discountList: action.payload,
      };
    case BookingTypes.GET_TRIP_WITH_PHISICAL_BUS:
      return {
        ...state,
        fetchingBooking: false,
        error: '',
        tripsWithPhysicalBusList: action.payload,
      };
    case BookingTypes.GET_TRIP_WITH_DATE_PHISICAL_BUS:
      return {
        ...state,
        fetchingBooking: false,
        error: '',
        tripsWithDatePhysicalBusList: action.payload,
      };
    case BookingTypes.GET_TODAY_TRIP_WITH_PHISICAL_BUS:
      return {
        ...state,
        fetchingBooking: false,
        error: '',
        todayTripsWithPhysicalBusList: action.payload,
      };
    case BookingTypes.SET_BOOKED_VEHICLES:
      return {
        ...state,
        fetchingBooking: false,
        error: '',
        bookedVehiclesList: action.payload,
      };
    case BookingTypes.SET_BOOKED_MANIFEST:
      return {
        ...state,
        fetchingBooking: false,
        error: '',
        manifestDetails: action.payload,
      };
    case BookingTypes.GET_ALL_FARES:
      return {
        ...state,
        fetchingBooking: false,
        error: '',
        fareList: action.payload,
      };
    case BookingTypes.GET_ALL_FARES_AMOUNT:
      return {
        ...state,
        fetchingBooking: false,
        error: '',
        fareAmountDetails: action.payload,
      };
    case BookingTypes.GET_HIRE_SERVICE_REPORT:
      return {
        ...state,
        fetchingBooking: false,
        error: '',
        hireServiceReportList: action.payload,
      };
    case BookingTypes.FARE_CALENDER:
      return {
        ...state,
        fetchingBooking: false,
        error: '',
        fareCalenderList: action.payload,
      };
    case BookingTypes.GET_EBM:
      return {
        ...state,
        fetchingBooking: false,
        error: '',
        ebmList: action.payload,
      };
    case BookingTypes.FLEET_HISTORY:
      return {
        ...state,
        loading: false,
        error: '',
        fleetHistoryList: action.payload,
      };
    case BookingTypes.BLOWN_BUS_REPORT:
      return {
        ...state,
        loading: false,
        error: '',
        blownBusReport: action.payload,
      };
    case BookingTypes.GET_DEPARTURE_REPORT:
      return {
        ...state,
        loading: false,
        error: '',
        departureReport: action.payload,
      };
    case BookingTypes.FLEET_BY_COUNTRY:
      return {
        ...state,
        fetchingBooking: false,
        error: '',
        fleetMovementList: action.payload,
      };
    case BookingTypes.LISTING_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
        fetchingBooking: false,
        loadingMore: false,
      };
    case BookingTypes.USER_BOOKING_INFO_CLEAR_WITH_PHONE:
      return {
        ...state,
        userBookingDetails: {},
        userBookinginfomation: {},
        availableTripsList: {},
      };
    case BookingTypes.USER_BOOKING_INFO_CLEAR:
      return {
        ...state,
        userBookingDetails: {},
        availableTripsList: {},
      };
    case BookingTypes.LISTING_RESET:
      return {
        ...state,
        loading: false,
        error: '',
        fetchingBooking: false,
        loadingMore: false,
      };
    case BookingTypes.LOGOUT:
      return {
        ...state,
        loading: false,
        inComingCount: true,
        error: '',
        stateList: [],
        fuelCostList: [],
        fixedChargesList: [],
        regionList: {},
        countryList: [],
        discountList: [],
        routeList: {},
        routePaginatedList: {},
        outgoingJourney: {},
        incomingJourney: {},
        newRouteFare: {},
        terminalList: {},
        terminalByDestinationList: [],
        onlineRouteList: [],
        availableTripsList: {},
        fetchingBooking: false,
        bookingList: {},
        rescheduleRerouteList: {},
        userBookingDetails: {},
        userBookinginfomation: {},
        moreTickets: [],
        loadingMore: false,
        availableSeatInfo: {},
        bookingCharges: 0.0,
        pickUpPointList: {},
        ticketSalesRecordList: {},
        tripList: {},
        hireBookingList: {},
        hiredVehicleList: {},
        bookedVehiclesList: [],
        manifestDetails: {},
        pendingHireRequest: {},
        pendingHireDetails: {},
        ebmList: [],
        tripsWithPhysicalBusList: {},
        tripsWithDatePhysicalBusList: { userData: [] },
        todayTripsWithPhysicalBusList: {},
        fareList: {},
        hireServiceReportList: { userData: [] },
        fareCalenderList: {},
        fleetHistoryList: { userData: [] },
        blownBusReport: { userData: [] },
        departureReport: { userData: [] },
        fleetMovementList: [],
        extraDispatchList: { userData: [] },
        blownBusList: { userData: [] },
        fleetUsageReportList: [],
        partnerVehicleReport: [],
        partnerVehicleRepairReport: [],
        opsFleetSummary: [],
        opsFleetTerminalSummary: [],
        fleetFinancialList: { userData: [] },
        allFleetFinancialList: { userData: [] },
        captainRatingList: { userData: [] },
        captainRatingDetails: [],
        captainRequestList: { userData: [] },
        captainSwapList: { userData: [] },
        businessPartnerList: {},
        businessPartnerPaymentList: {},
        businessPartnerBookingList: { userData: [] },
        bookChannelReportList: {},
        bookingAnalysis: {},
      };

    default:
      return state;
  }
};

export default bookingsReducer;
